$purple: rgb(89, 21, 226);
$pink: #c251e4;
$white: #fff;
$dark: #312e2d;
$red: #e84e45;
$yellow: #eaab24;
$green: #0ca724;
$lightgray: #aedadc;
$link: #00a6ed;

$transition: all 190ms ease;

.shadowed {
  box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.7);
}
