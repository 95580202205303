@import '../../variables.modules';

.hobby {
  li {
    display: flex;
    justify-content: wrap;
    padding-left: 2rem;
    line-height: 1.2;
    letter-spacing: 1.5px;
  }
  @media only screen and (min-width: 940px) {
    li {
      font-size: 1.2rem;
    }
  }
}

.pink {
  color: $pink;
}

.green {
  color: $green;
}

.skills {
  columns: 1;

  li {
    display: flex;
    justify-content: wrap;
    padding-left: 2rem;
    line-height: 1.2;
    letter-spacing: 2px;
  }

  @media only screen and (min-width: 940px) {
    columns: 2;

    li {
      letter-spacing: 2px;
      font-size: 1.2rem;
    }
  }
}
