@import '../variables.modules';

.dark {
  background: #090806;

  color: #d06731;
  transition: all 600ms;

  a,
  i,
  li {
    color: #4da5b8;
    transition: color 700ms;
  }

  li:hover {
    color: $white;
    transform: translateY(-3px);
    transition: $transition;
  }

  i:hover {
    color: $lightgray;
    transform: translateY(-3px);
    transition: $transition;
  }

  a:hover {
    color: $lightgray;
  }

  footer {
    color: $lightgray;
  }
}

.light {
  background: #a6d1d6;

  color: #d13c2f;
  transition: all 700ms;

  a:hover {
    color: #e85a50;
  }
}

i:hover {
  color: #e85a50;
  transform: translateY(-3px);
  transition: $transition;
}

footer {
  color: $dark;
  font-size: 0.7rem;
  text-align: center;
  padding: 0.3%;

  width: 100vw;
}
