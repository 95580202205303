@import './variables.modules';

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  width: 100vw;
  height: 100vh;
  color: $dark;
  background-color: $dark;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

li {
  color: $dark;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a,
a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none;
}

img {
  max-width: 80%;
}

ul {
  list-style-type: none;
}
