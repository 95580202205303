@import '../variables.modules';

nav {
  transition: $transition;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 0rem;
}

.active {
  border-bottom: 0.1rem solid;
  border-image-slice: 1;
}

li {
  transition: $transition;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;

  &:hover {
    transform: translateY(-2px);
    transition: $transition;
    color: none;
  }
}

.link:hover {
  color: $white;
}
